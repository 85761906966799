import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"

const About = () => (
  <Layout>
    <SEO title="Contact | Tick Tock Inc." />
    <div className="ticktockinc-container">
      <h1 className="is-size-3 has-text-weight-bold">Contact</h1>
      <br />
      <p className="is-size-5">
        {" "}
        Tick Tock Inc. is one of the leading distributors of general merchandise
        to dollar, discount, convenient, and supermarket stores. Having been in
        business for over 30 years, Tick Tock Inc. carries over 1,000 essential
        and fast-selling items and is the premier distributor for all of your
        retail needs.
      </p>
      <br />

      <p className="is-size-4 has-text-weight-bold">
        240 Moonachie Avenue. Suite D, Moonachie NJ 07074
      </p>
      <br />

      <p className="is-size-4 has-text-weight-bold">
        Phone: 201-853-9200 <br /> Fax: 201-853-9477 <br /> Email:
        tiktok@att.net
      </p>
    </div>
  </Layout>
)

export default About
